import avtar from "../../assets/images/default-avatar.jpg";
import { Link } from "react-router-dom";
import { PiUserCircle } from "react-icons/pi";
import { FiUsers } from "react-icons/fi";
import { SlSettings } from "react-icons/sl";
import WebService from "../../Services/WebService";
import { Spinner, Dropdown } from "react-bootstrap";
import { useEffect, useState } from "react";
import HelperService from "../../Services/HelperService";
import { toast } from "react-toastify";

interface PropsData {
  profileDetails?: any;
}
const ProfileDropdown = (props: PropsData) => {
  const [flag, setFlag] = useState(false);
  const [getUserType, setUserType] = useState<any>("");
  const [profile_image, setProfileImage] = useState<any>(avtar);
  const [subscription, setSubscription] = useState("-");
  useEffect(() => {
    if (localStorage.getItem("user_mode") !== "SUPER_ADMIN")
      getMySubscription();
    if (props?.profileDetails !== null) {
      setProfileImage(props?.profileDetails?.profile_image);
      setUserType(localStorage.getItem("user_mode"));
    }
  }, [props?.profileDetails]);
  const handleLogout = () => {
    setFlag(true);
    return WebService.deleteAPI({
      action: `logout`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        setFlag(false);
        WebService.logout();
      })
      .catch((error: any) => {
        setFlag(false);
        return error;
      });
  };
  const getMySubscription = () => {
    return WebService.getAPI({
      action: `my-subscription`,
      body: null,
      isShowError: true,
    })
      .then((res: any) => {
        if (res?.info && res?.info?.subscription) {
          setSubscription(res?.info?.company?.name);
        }
      })
      .catch((error: any) => {
        toast.error(error?.response?.data?.message);
        return error;
      });
  };
  return (
    <>
      <div className="px-lg-3 mobile-profile-dd">
        <div className="d-flex gap-3 align-items-center border-bottom pb-3 mb-3">
          <img
            src={profile_image ?? avtar}
            width={60}
            height={60}
            className=" object-fit-cover rounded-3"
            alt=""
          />
          <div>
            <h4 className="font-14 font-semibold">
              {props?.profileDetails?.first_name ?? ""}
              {props?.profileDetails?.last_name
                ? " " + props?.profileDetails?.last_name
                : "-"}
            </h4>
            
            {localStorage.getItem("user_mode")!=="SUPER_ADMIN" &&(
            <p className="font-12 ">
              <span className="text-secondary">Subscription:</span>
              <span className="fort-bold">{subscription}</span>
            </p>)}
          </div>
        </div>
        {getUserType === "SUPER_ADMIN" ||
        getUserType === "SUPER_ADMIN_EMPLOYEE" ? (
          <div className="border-bottom pb-3 mb-3">
            <Link to="/profile-setting" className=" dropdown-item">
              <PiUserCircle size={18} className="icon" /> My Profile
            </Link>
          </div>
        ) : (
          <>
            <div className="border-bottom pb-3 mb-3">
              <Dropdown.Item href="/profile-setting">
                <Link to="/profile-setting" className=" dropdown-item">
                  <PiUserCircle size={18} className="icon" /> My Profile
                </Link>
              </Dropdown.Item>
              {(localStorage.getItem("user_mode") === "PROPERTY_MANAGER" ||
                localStorage.getItem("user_mode") === "PROPERTY_OWNER") && (
                <Dropdown.Item href="/select-organization">
                  <Link to="/select-organization" className=" dropdown-item">
                    <FiUsers size={18} className="icon" /> Switch Organization
                  </Link>
                </Dropdown.Item>
              )}
              <Dropdown.Item href="/profile-setting">
                <Link to="/profile-setting" className=" dropdown-item mb-0">
                  <SlSettings size={18} className="icon" /> Settings
                </Link>
              </Dropdown.Item>
            </div>
            {/* <Link to="javascript:void(0)" className=" dropdown-item">
              <PiQuestion size={18} className="icon" /> Help Center
            </Link> */}
          </>
        )}
        <Dropdown.Item href="javascript:void(0)">
          <Link
            to="javascript:void(0)"
            className=" dropdown-item"
            onClick={() => {
              handleLogout();
            }}
          >
            <svg
              width="18"
              height="18"
              className="icon"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.99998 2.54651C5.43582 2.54651 2.54651 5.43584 2.54651 9C2.54651 12.5642 5.43582 15.4535 8.99998 15.4535C11.388 15.4535 13.4738 14.1567 14.5904 12.2264C14.7351 11.9762 15.0552 11.8907 15.3053 12.0354C15.5555 12.1801 15.641 12.5002 15.4963 12.7504C14.2003 14.9908 11.7767 16.5 8.99998 16.5C4.85785 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85785 1.5 8.99998 1.5C11.7767 1.5 14.2003 3.00923 15.4963 5.24962C15.641 5.49977 15.5555 5.81986 15.3053 5.96456C15.0552 6.10926 14.7351 6.02378 14.5904 5.77364C13.4738 3.84332 11.388 2.54651 8.99998 2.54651ZM9.37002 5.8393C9.57436 6.04365 9.57436 6.37496 9.37002 6.5793L7.47258 8.47674H15.9767C16.2657 8.47674 16.5 8.71101 16.5 9C16.5 9.28899 16.2657 9.52326 15.9767 9.52326H7.47258L9.37002 11.4207C9.57436 11.625 9.57436 11.9564 9.37002 12.1607C9.16568 12.365 8.83437 12.365 8.63003 12.1607L5.83934 9.37C5.635 9.16565 5.635 8.83435 5.83934 8.63L8.63003 5.8393C8.83437 5.63496 9.16568 5.63496 9.37002 5.8393Z"
                fill="#4A5264"
              />
            </svg>
            <span className="me-2">Sign Out</span>
            {flag ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                style={{ borderWidth: "2px" }}
              >
                <span className="sr-only"></span>
              </Spinner>
            ) : (
              ""
            )}
          </Link>
        </Dropdown.Item>
      </div>
    </>
  );
};
export default ProfileDropdown;
